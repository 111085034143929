import React from 'react';
import { useRecoilState } from 'recoil';
import { StyledDarkModeSwitch } from './DarkModeSwitch.style';

// Recoil States
import { isDarkThemeState } from '../../atoms';

const DarkModeSwitch = (props) => {
  const [isDarkTheme, setIsDarkTheme] = useRecoilState(isDarkThemeState);

  const onDarkModeSwitchClicked = (oEvent) => {
    setIsDarkTheme(!isDarkTheme);
  };

  return (
    <StyledDarkModeSwitch onClick={onDarkModeSwitchClicked}>
      {isDarkTheme ? 'light_mode' : 'dark_mode'}
    </StyledDarkModeSwitch>
  );
};

export default DarkModeSwitch;
