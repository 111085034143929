import React from 'react';
import {
  StyledHeaderBar,
  StyledHeaderBarContainer,
  StyledHeaderBarTitle,
  StyledHeaderBarLinksBox,
  StyledHeaderBarLink
} from './HeaderBar.style';
import { ContentBox, DarkModeSwitch, FlexBox } from '../';
import { Link } from 'gatsby';

const HeaderBar = ({ headerLinks, children }) => {
  return (
    <StyledHeaderBar>
      <ContentBox>
        <StyledHeaderBarContainer>
          <Link className="hideOnMobile" to="/">
            <StyledHeaderBarTitle>Rafael López Martínez</StyledHeaderBarTitle>
          </Link>
          <StyledHeaderBarLinksBox>
            <FlexBox flexDirection="row">
              <Link className="showOnMobileOnly" key="home" to="/">
                <StyledHeaderBarLink>Home</StyledHeaderBarLink>
              </Link>
              {headerLinks.map((link) => (
                <Link key={link.text} to={link.to}>
                  <StyledHeaderBarLink>{link.text}</StyledHeaderBarLink>
                </Link>
              ))}
            </FlexBox>
            <DarkModeSwitch />
          </StyledHeaderBarLinksBox>
        </StyledHeaderBarContainer>
      </ContentBox>
    </StyledHeaderBar>
  );
};

export default HeaderBar;
