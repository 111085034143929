import { styled } from 'styled-components';

const StyledTagButton = styled.button`
  border: 1px solid;
  border-color: ${(props) => (props.isSelected ? props.theme.colors.emphasized : props.theme.colors.text.secondary)};
  border-radius: 5px;
  box-sizing: border-box;
  margin: 2px;
  padding: 3px 10px;
  background: none;
  cursor: pointer;
  color: ${(props) => (props.isSelected ? props.theme.colors.emphasized : '')};

  &:hover {
    border-color: ${(props) => props.theme.colors.emphasized};
  }

  &:active {
    border-color: ${(props) => props.theme.colors.emphasized};
    color: ${(props) => props.theme.colors.emphasized};
  }
`;

export { StyledTagButton };
