import { styled } from 'styled-components';

const StyledPostListItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  list-style: none;
  border-radius: 5px;
  box-sizing: border-box;
  background: none;
  cursor: pointer;
  font-weight: 900;
  font-size: 1em;
  line-height: 1.8em;
  padding: 2px 0;
  @media (min-width: ${(props) => props.theme.screenSizeBreakpoints.xs}) {
    flex-direction: row;
  }

  * {
    color: ${(props) => (props.isSelected ? props.theme.colors.emphasized : '')};
    text-decoration: none;
  }

  a {
    color: ${(props) => props.theme.colors.text.primary};
  }

  #date {
    font-weight: 400;
    font-size: 0.7em;
    color: ${(props) => props.theme.colors.emphasized};
    padding-top: 5px;

    @media (min-width: ${(props) => props.theme.screenSizeBreakpoints.xs}) {
      font-size: 0.9em;
      min-width: 200px;
      padding-top: 0px;
    }
  }

  &:hover {
    * {
      color: ${(props) => props.theme.colors.emphasized};
    }
    a {
      text-decoration: underline;
    }
  }

  &:after {
    position: absolute;
    bottom: 0;
    content: ' ';
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
  }
`;

export { StyledPostListItem };
