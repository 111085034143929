import { typography, screenSizeBreakpoints, contentMaxWidth } from './common.theme';

const colors = {
  primary: '#0B192F',
  secondary: '#283f5f',
  tertiary: '#122541',
  emphasized: '#F46663',
  detail: '#dc3927',
  text: {
    primary: '#c2cee0',
    secondary: '#626d8e',
    tertiary: '',
    emphasized: '#F46663',
    detail: '#dc3927'
  },
  heading: {
    primary: '#ecf1f7',
    secondary: '#626d8e',
    tertiary: '',
    emphasized: '#F46663',
    detail: '#dc3927'
  }
};

const theme = {
  colors: colors,
  typography: typography,
  screenSizeBreakpoints: screenSizeBreakpoints,
  contentMaxWidth: contentMaxWidth
};

export default theme;
