import styled from 'styled-components';

const StyledDarkModeSwitch = styled.button`
  cursor: pointer;
  background-color: #fffff2 !important;
  color: #0b192f;
  border: 1px solid #c2cee0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-family: 'Material Icons Outlined' !important;
  font-size: 20px;
  font-weight: 300 !important;
`;

export { StyledDarkModeSwitch };
