import { typography, screenSizeBreakpoints, contentMaxWidth } from './common.theme';

const colors = {
  primary: '#FFFFF2',
  secondary: '#d5d5d8',
  tertiary: '#ecf1f7',
  emphasized: '#e25855',
  detail: '#F46663',
  text: {
    primary: '#061328',
    secondary: '#182d4c',
    tertiary: '#626d8e',
    emphasized: '#F46663',
    detail: '#dc3927'
  },
  heading: {
    primary: '#061328',
    secondary: '#626d8e',
    tertiary: '#182d4c',
    emphasized: '#F46663',
    detail: '#dc3927'
  }
};

const theme = {
  colors: colors,
  typography: typography,
  screenSizeBreakpoints: screenSizeBreakpoints,
  contentMaxWidth: contentMaxWidth
};

export default theme;
