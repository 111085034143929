import { styled } from 'styled-components';

const StyledSideBarCard = styled.div`
  border: 1px solid ${(props) => props.theme.colors.secondary};
  border-radius: 10px;
  padding: 20px;
  margin-top: 25px;
  background-color: ${(props) => props.theme.colors.tertiary};
`;

export { StyledSideBarCard };
