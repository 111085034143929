import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { SideBarCard } from '../';

const PersonalSideBarCards = (props) => {
  return (
    <>
      <SideBarCard>
        <h6>Me</h6>
        <StaticImage
          formats={['auto', 'webp', 'avif']}
          src="../../images/me-selfie.jpeg"
          alt="Me"
          style={{ borderRadius: '10px', maxWidth: '400px', alignSelf: 'center' }}
        />
      </SideBarCard>
      <SideBarCard>
        <h6>Me On The Internet</h6>
        <ul>
          <li>
            <a href="https://linkedin.com/in/rafaellopezmartinez" target="_blank">
              LinkedIn
            </a>
          </li>
          <li>
            <a href="https://github.com/rlopmar" target="_blank">
              Github
            </a>
          </li>
          <li>
            <a href="https://twitter.com/rlopmar" target="_blank">
              Twitter
            </a>
          </li>
          <li>
            <a href="https://stackoverflow.com/users/7655348/rafael-l%c3%b3pez-mart%c3%adnez" target="_blank">
              Stackoverflow
            </a>
          </li>
        </ul>
      </SideBarCard>
    </>
  );
};

export default PersonalSideBarCards;
