import React from 'react';
import { StyledPostListItem } from './PostListItem.style';

const PostListItem = (props) => {
  return (
    <StyledPostListItem {...props}>
      <span id="date">{props.postDate}</span>
      <span>{props.children}</span>
    </StyledPostListItem>
  );
};

export default PostListItem;
