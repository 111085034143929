import { styled } from 'styled-components';

const StyledHeaderBar = styled.header`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.primary};
  border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
  box-shadow: 0 2.2px 2.2px rgba(0, 0, 0, 0.07), 0 5.3px 5.3px rgba(0, 0, 0, 0.05), 0 10px 10px rgba(0, 0, 0, 0.042),
    0 17.9px 17.9px rgba(0, 0, 0, 0.035), 0 33.4px 33.4px rgba(0, 0, 0, 0.028), 0 80px 80px rgba(0, 0, 0, 0.02);
`;

const StyledHeaderBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 75px;
  width: 100%;

  * {
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.scaleRatio}rem;
    font-weight: 900;
  }

  a {
    display: flex;
    align-items: stretch;
    text-decoration: none;
  }
`;

const StyledHeaderBarTitle = styled.span`
  display: flex;
  padding: 0 0.5em;
  flex-grow: 1;
  color: ${(props) => props.theme.colors.heading.primary};

  @media (min-width: ${(props) => props.theme.screenSizeBreakpoints.xs}) {
    display: flex;
  }
`;

const StyledHeaderBarLinksBox = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.heading.primary};
  div {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 30px;
  }
  a {
    text-decoration: none;
  }
  &:hover {
    color: ${(props) => props.theme.colors.heading.emphasized};
  }

  @media (min-width: ${(props) => props.theme.screenSizeBreakpoints.xs}) {
    width: auto;
    gap: 30px;
  }
`;

const StyledHeaderBarLink = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.heading.primary};
  a {
    text-decoration: none;
  }
  &:hover {
    color: ${(props) => props.theme.colors.heading.emphasized};
  }
`;

export {
  StyledHeaderBar,
  StyledHeaderBarContainer,
  StyledHeaderBarTitle,
  StyledHeaderBarLinksBox,
  StyledHeaderBarLink
};
