import { styled } from 'styled-components';

const StyledContentBox = styled.div`
  max-width: ${(props) => props.theme.contentMaxWidth};
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;

  // Tablet
  @media only screen and (min-width: ${(props) => props.theme.screenSizeBreakpoints.xs}) {
    font-size: ${(props) => props.theme.typography.baseFontSize.s};
    padding: 0 40px;
  }

  // small screen
  @media only screen and (min-width: ${(props) => props.theme.screenSizeBreakpoints.s}) {
    font-size: ${(props) => props.theme.typography.baseFontSize.m};
  }

  // medium screen
  @media only screen and (min-width: ${(props) => props.theme.screenSizeBreakpoints.m}) {
    max-width: ${(props) => props.theme.contentMaxWidth};
    font-size: ${(props) => props.theme.typography.baseFontSize.l};
    margin: 0 auto 0 auto;
  }

  // Big screen
  @media only screen and (min-width: ${(props) => props.theme.screenSizeBreakpoints.l}) {
  }
`;

export { StyledContentBox };
