import styled from 'styled-components';

const FooterWrapper = styled.footer`
  justify-self: flex-end;
  width: 100%;
  margin: 50px 0;
  * {
    color: ${(props) => props.theme.colors.text.primary};
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
`;

const FooterCenteredBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FooterItemsRow = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  flex-direction: row;
  font-weight: 500;
  padding: 10px 0;
`;

const FooterLinkWithIcon = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;

export { FooterWrapper, FooterCenteredBox, FooterItemsRow, FooterLinkWithIcon };
