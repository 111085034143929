import styled from 'styled-components';

const commonStyles = (props) => {
  return `
    font-family: ${props.theme.typography.fontFamily[props.fontFamily] || props.fontFamily || 'inherit'};
  `;
};
const textCommonStyles = (props) => {
  return `
    color: ${props.theme.colors.text[props.color] || props.color || props.theme.colors.text.primary};
    ${(props) => commonStyles(props)}
  `;
};
const headingCommonStyles = (props) => {
  return `
    color: ${props.theme.colors.heading[props.color] || props.color || props.theme.colors.heading.primary};
    ${(props) => commonStyles(props)}
  `;
};

// Typography components

const H1 = styled.h1`
  font-size: calc(1em * ${(props) => Math.pow(props.theme.typography.scaleRatio, 6)});
  font-weight: ${(props) => props.theme.typography.fontWeight.h1};
  margin: ${(props) => props.theme.typography.marginTop.h1} 0 ${(props) => props.theme.typography.marginBottom.h1} 0;
  ${(props) => headingCommonStyles(props)}
`;

const H2 = styled.h2`
  font-size: calc(1em * ${(props) => Math.pow(props.theme.typography.scaleRatio, 5)});
  font-weight: ${(props) => props.theme.typography.fontWeight.h2};
  margin: ${(props) => props.theme.typography.marginTop.h2} 0 ${(props) => props.theme.typography.marginBottom.h2} 0;
  ${(props) => headingCommonStyles(props)}
`;

const H3 = styled.h3`
  font-size: calc(1em * ${(props) => Math.pow(props.theme.typography.scaleRatio, 4)});
  font-weight: ${(props) => props.theme.typography.fontWeight.h3};
  margin: 0 ${(props) => props.theme.typography.marginTop.h3} 0 ${(props) => props.theme.typography.marginBottom.h3} 0;
  ${(props) => headingCommonStyles(props)}
`;

const H4 = styled.h4`
  font-size: calc(1em * ${(props) => Math.pow(props.theme.typography.scaleRatio, 3)});
  font-weight: ${(props) => props.theme.typography.fontWeight.h4};
  margin: ${(props) => props.theme.typography.marginTop.h4} 0 ${(props) => props.theme.typography.marginBottom.h4} 0;
  ${(props) => headingCommonStyles(props)}
`;

const H5 = styled.h5`
  font-size: calc(1em * ${(props) => Math.pow(props.theme.typography.scaleRatio, 2)});
  font-weight: ${(props) => props.theme.typography.fontWeight.h5};
  margin: ${(props) => props.theme.typography.marginTop.h5} 0 ${(props) => props.theme.typography.marginBottom.h5} 0;
  ${(props) => headingCommonStyles(props)}
`;

const H6 = styled.h6`
  font-size: calc(1em * ${(props) => Math.pow(props.theme.typography.scaleRatio, 1)});
  font-weight: ${(props) => props.theme.typography.fontWeight.h6};
  margin: ${(props) => props.theme.typography.marginTop.h6} 0 ${(props) => props.theme.typography.marginBottom.h6} 0;
  ${(props) => headingCommonStyles(props)}
`;

const P = styled.p`
  font-size: 1em;
  font-weight: ${(props) => props.theme.typography.fontWeight.p};
  line-height: 1.7em;
  margin: ${(props) => props.theme.typography.marginTop.p} 0 ${(props) => props.theme.typography.marginBottom.p} 0;
  ${(props) => textCommonStyles(props)};
  word-spacing: 0.2em;
`;

export { H1, H2, H3, H4, H5, H6, P };
