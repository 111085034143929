const contentMaxWidth = '1300px';

const screenSizeBreakpoints = {
  xs: '576px', // Mobile (max-width) ==> Tablet (min-width)
  s: '768px', // Tablet (max-width) ==> small screen (min-width)
  m: '1024px', // Laptop - small screen (max-width) ==> Desktop - large screen (min-width)
  l: '1200px' // Desktop - large screen (max-width) ===> UltraWide screen / TV - Extra large screen (min-width)
};

const typography = {
  fontUrls: ['https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap'],
  fontFamily: 'Montserrat,sans-serif',
  baseFontSize: {
    xs: '14px',
    s: '16px',
    m: '16px',
    l: '16px',
    xl: '16px'
  },
  scaleRatio: 1.2,
  letterSpacing: '',
  wordSpacing: '0.2em',
  fontWeight: {
    h1: '',
    h2: '',
    h3: '',
    h4: '',
    h5: '',
    h6: '',
    p: '500'
  },
  marginTop: {
    h1: '0.75em',
    h2: '0.2em',
    h3: '0.2em',
    h4: '0.2em',
    h5: '0.2em',
    h6: '0.2em',
    p: '0.75em'
  },
  marginBottom: {
    h1: '0.75em',
    h2: '0.375em',
    h3: '0.75em',
    h4: '0.75em',
    h5: '1em',
    h6: '1em',
    p: '1.25em'
  }
};

export { screenSizeBreakpoints, typography, contentMaxWidth };
