import { styled } from 'styled-components';

const StyledFlexBox = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection === 'row' || props.flexDirection === 'column' ? props.flexDirection : 'column'};
  flex-grow: 1;
  box-sizing: border-box;
  padding: ${(props) => props.padding || '0'};
  max-width: ${(props) => props.maxWidth || 'inherit'};
  width: ${(props) => props.width || 'inherit'};
  min-width: ${(props) => props.minWidth || 'inherit'};
  align-items: ${(props) => {
    return props.alignItems || 'initial';
  }};
  ${(props) => {
    if (props.fullWidth) return 'width: 100%;';
  }};
`;

export { StyledFlexBox };
