import React from 'react';

import { Link } from 'gatsby-link';
import { FooterWrapper, FooterCenteredBox, FooterItemsRow, FooterLinkWithIcon } from './Footer.style';
import { ContentBox } from '../';
import { StaticImage } from 'gatsby-plugin-image';

const Footer = () => {
  return (
    <FooterWrapper>
      <ContentBox>
        <FooterCenteredBox>
          <FooterItemsRow>
            <span className="hideOnMobile">Made by Rafael López Martínez</span>
            <a href="https://ko-fi.com/rafaellopezmartinez" target="_blank">
              Ko-Fi
            </a>
            <Link to="/rss" target="_blank">
              RSS
            </Link>
          </FooterItemsRow>
          <FooterItemsRow>
            <FooterLinkWithIcon>
              <a href="https://www.gatsbyjs.com" target="_blank">
                Gatsby
                <span> </span>
                <StaticImage
                  formats={['auto', 'webp', 'avif']}
                  src="../../images/gatsby-logo.png"
                  width={20}
                  height={20}
                  quality={20}
                  alt="Gatsby"
                />
              </a>
            </FooterLinkWithIcon>
            <FooterLinkWithIcon>
              <a href="https://styled-components.com/" target="_blank">
                Styled Components
                <span> </span>
                <StaticImage
                  formats={['auto', 'webp', 'avif']}
                  src="../../images/styled-components-icon.png"
                  width={20}
                  height={20}
                  quality={20}
                  alt="Styled Components"
                />
              </a>
            </FooterLinkWithIcon>
            <FooterLinkWithIcon>
              <a href="https://github.com/rlopmar" target="_blank">
                Github
                <span> </span>
                <StaticImage
                  formats={['auto', 'webp', 'avif']}
                  src="../../images/github-icon.png"
                  width={20}
                  height={20}
                  quality={20}
                  alt="Gatsby"
                />
              </a>
            </FooterLinkWithIcon>
            <FooterLinkWithIcon>
              <a href="https://www.netlify.com/" target="_blank">
                Netlify
                <span> </span>
                <StaticImage
                  formats={['auto', 'webp', 'avif']}
                  src="../../images/netlify-icon.webp"
                  width={20}
                  height={20}
                  quality={20}
                  alt="Gatsby"
                />
              </a>
            </FooterLinkWithIcon>
          </FooterItemsRow>
        </FooterCenteredBox>
      </ContentBox>
    </FooterWrapper>
  );
};

export default Footer;
