import { createGlobalStyle } from 'styled-components';

const commonTypographyStyles = (props) => {
  return `
    font-family: ${props.theme.typography.fontFamily[props.fontFamily] || props.fontFamily || 'inherit'};
  `;
};
const textCommonStyles = (props) => {
  return `
    color: ${props.theme.colors.text[props.color] || props.color || props.theme.colors.text.primary};
    ${(props) => commonTypographyStyles(props)}
  `;
};
const headingCommonStyles = (props) => {
  return `
    color: ${props.theme.colors.heading[props.color] || props.color || props.theme.colors.heading.primary};
    ${(props) => commonTypographyStyles(props)}
  `;
};

const GlobalStyle = createGlobalStyle`
  *:not(deckgo-highlight-code),
  :after,
  :before {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${(props) => props.theme.typography.fontFamily};
    color:  ${(props) => props.theme.colors.text.primary};
    letter-spacing: ${(props) => props.theme.typography.letterSpacing};
    word-spacing: ${(props) => props.theme.typography.wordSpacing};
    font-size: ${(props) => props.theme.typography.baseFontSize.xs};
  }

  body {
    background-color: ${(props) => props.theme.colors.primary};
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;

    table {
      border: 1px solid ${(props) => props.theme.colors.secondary};
      border-collapse: collapse;

      th {
        font-weight: 900;
        padding: 0;
      }

      tr, td {
        border: 1px solid ${(props) => props.theme.colors.secondary};
      }

      td {
        padding: 10px
      }
    }
    blockquote {
      font-style: italic;
      opacity: 0.7;
      padding-left: 10px;
      margin: 20px;
      border-left: 4px solid ${(props) => props.theme.colors.secondary};
    }
  }

  .showOnMobileOnly {
      // Tablet
      @media only screen and (min-width: ${(props) => props.theme.screenSizeBreakpoints.xs}) {
        display: none !important;
      }
    }

  .hideOnMobile {
    // Tablet
    @media only screen and (max-width: ${(props) => props.theme.screenSizeBreakpoints.xs}) {
      display: none !important;
    }
  }

  .hideOnTablet {
    // Tablet
    @media only screen and (max-width: ${(props) => props.theme.screenSizeBreakpoints.s}) {
      display: none !important;
    }
  }

  .hideOnLaptop {
    // Tablet
    @media only screen and (max-width: ${(props) => props.theme.screenSizeBreakpoints.m}) {
      display: none !important;
    }
  }
  
  // Syntax highlighting
  .gatsby-highlight-code-line {
    background-color: #4d4d4d;
    display: block;
    margin-right: -1em;
    margin-left: -1em;
    padding-right: 1em;
    padding-left: 0.75em;
    border-left: 0.25em solid #DB3926;
  }

  /* Adjust the position of the line numbers */
  .gatsby-highlight pre[class*="language-"].line-numbers {
    padding-left: 2.8em;
  }

  .gatsby-highlight {
    background-color: #2D2D2D;
    border-radius: 0.3em;
    margin: 0.5em 0;
    padding: 1em;
    overflow: auto;
  }

  .gatsby-highlight pre[class*="language-"].line-numbers {
    padding: 0;
    padding-left: 2.8em;
    overflow: initial;
  }
  .gatsby-highlight .line-numbers-rows {
    line-height: 1.53
  }

  .command-line-prompt > span:before {
    color: #999;
    content: " ";
    display: block;
    padding-right: 0.8em;
  }

  /* Prompt for all users */
  .command-line-prompt > span[data-user]:before {
    content: "[" attr(data-user) "@" attr(data-host) "] $";
  }

  /* Prompt for root */
  .command-line-prompt > span[data-user="root"]:before {
    content: "[" attr(data-user) "@" attr(data-host) "] #";
  }

  .command-line-prompt > span[data-prompt]:before {
    content: attr(data-prompt);
  }

  // Typography

  // Tablet
  @media only screen and (min-width: ${(props) => props.theme.screenSizeBreakpoints.xs}) {
    *:not(deckgo-highlight-code),
    :after,
    :before {
      font-size: ${(props) => props.theme.typography.baseFontSize.s}
    }
  }

  // small screen
  @media only screen and (min-width: ${(props) => props.theme.screenSizeBreakpoints.s}) {
    *:not(deckgo-highlight-code),
    :after,
    :before {
      font-size: ${(props) => props.theme.typography.baseFontSize.m}
    }
  }

  // medium screen
  @media only screen and (min-width: ${(props) => props.theme.screenSizeBreakpoints.m}) {
    *:not(deckgo-highlight-code),
    :after,
    :before {
      font-size: ${(props) => props.theme.typography.baseFontSize.l}
    }
  }

  // Big screen
  @media only screen and (min-width: ${(props) => props.theme.screenSizeBreakpoints.l}) {
    *:not(deckgo-highlight-code),
    :after,
    :before {
      font-size: ${(props) => props.theme.typography.baseFontSize.xl};
    }
  }

  h1 {
    font-size: calc(1em * ${(props) => Math.pow(props.theme.typography.scaleRatio, 6)});
    font-weight: ${(props) => props.theme.typography.fontWeight.h1};
    margin: ${(props) => props.theme.typography.marginTop.h1} 0 ${(props) => props.theme.typography.marginBottom.h1} 0;
    ${(props) => headingCommonStyles(props)}
  }
  h2{
    font-size: calc(1em * ${(props) => Math.pow(props.theme.typography.scaleRatio, 5)});
    font-weight: ${(props) => props.theme.typography.fontWeight.h2};
    margin: ${(props) => props.theme.typography.marginTop.h2} 0 ${(props) => props.theme.typography.marginBottom.h2} 0;
    ${(props) => headingCommonStyles(props)};
  }
  h3{
    position:relative;
    font-size: calc(1em * ${(props) => Math.pow(props.theme.typography.scaleRatio, 4)});
    font-weight: ${(props) => props.theme.typography.fontWeight.h3};
    margin: 0 ${(props) => props.theme.typography.marginTop.h3} 0 ${(props) =>
  props.theme.typography.marginBottom.h3} 0;
    ${(props) => headingCommonStyles(props)};

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: ' ';
      width: 100%;
      border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
    }
  }
  h4{
    font-size: calc(1em * ${(props) => Math.pow(props.theme.typography.scaleRatio, 3)});
    font-weight: ${(props) => props.theme.typography.fontWeight.h4};
    margin: ${(props) => props.theme.typography.marginTop.h4} 0 ${(props) => props.theme.typography.marginBottom.h4} 0;
    ${(props) => headingCommonStyles(props)}
  }
  h5{
    font-size: calc(1em * ${(props) => Math.pow(props.theme.typography.scaleRatio, 2)});
    font-weight: ${(props) => props.theme.typography.fontWeight.h5};
    margin: ${(props) => props.theme.typography.marginTop.h5} 0 ${(props) => props.theme.typography.marginBottom.h5} 0;
    ${(props) => headingCommonStyles(props)}
  }
  h6{
    font-size: calc(1em * ${(props) => Math.pow(props.theme.typography.scaleRatio, 1)});
    font-weight: ${(props) => props.theme.typography.fontWeight.h6};
    margin: ${(props) => props.theme.typography.marginTop.h6} 0 ${(props) => props.theme.typography.marginBottom.h6} 0;
    ${(props) => headingCommonStyles(props)}
  }
  p{
    font-size: 1em;
    font-weight: ${(props) => props.theme.typography.fontWeight.p};
    line-height: 1.7em;
    margin: ${(props) => props.theme.typography.marginTop.p} 0 ${(props) => props.theme.typography.marginBottom.p} 0;
    ${(props) => textCommonStyles(props)};
  }
  
  main {
    a {
      color: ${(props) => props.theme.colors.text.emphasized};

      &:hover {
        color: white;
        text-decoration: none;
        background-color: ${(props) => props.theme.colors.text.emphasized};
      }
    }
  }


  // Lists
  li{
    line-height: 2em;
    a:hover {
      color: ${(props) => props.theme.colors.text.primary};
      text-decoration: none;
      background-color: transparent;
    }
  }
`;

export { GlobalStyle };
