import React from 'react';
import { StyledCategoryListItem } from './CategoryListItem.style';

const CategoryListItem = (props) => {
  return (
    <StyledCategoryListItem {...props}>
      <span>{props.children}</span>
      <span>{props.itemsCount}</span>
    </StyledCategoryListItem>
  );
};

export default CategoryListItem;
