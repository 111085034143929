import { styled } from 'styled-components';

const StyledSideBar = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  // medium screen
  @media only screen and (min-width: ${(props) => props.theme.screenSizeBreakpoints.m}) {
    justify-self: end;
    width: 400px;
  }
`;

export { StyledSideBar };
