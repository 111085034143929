import { styled } from 'styled-components';

const StyledCategoryListItem = styled.li`
  display: flex;
  justify-content: space-between;
  border: 1px solid transparent;
  list-style: none;
  border-radius: 5px;
  padding: 1px 10px;
  margin: 0;
  box-sizing: border-box;
  background: none;
  cursor: pointer;
  font-weight: 900;
  font-size: 0.9em;

  * {
    color: ${(props) => (props.isSelected ? props.theme.colors.emphasized : '')};
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.primary};
    * {
      color: ${(props) => props.theme.colors.emphasized};
      font-weight: 900;
    }
  }
`;

export { StyledCategoryListItem };
