// custom typefaces
import '@fontsource-variable/montserrat';
import '@fontsource/merriweather';

// Highlighting for code blocks
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import 'prismjs/plugins/command-line/prism-command-line.css';

import React from 'react';
import { RecoilRoot, useRecoilValue } from 'recoil';
import { ThemeProvider } from 'styled-components';

// Components
import { GlobalStyle } from './src/components';

// Recoil States
import { isDarkThemeState } from './src/atoms';

// Themes
import { lightTheme, darkTheme } from './src/themes';

const Theme = ({ children }) => {
  const isDarkTheme = useRecoilValue(isDarkThemeState);

  return <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>{children}</ThemeProvider>;
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <RecoilRoot {...props}>
      <Theme>
        <GlobalStyle />
        {element}
      </Theme>
    </RecoilRoot>
  );
};
