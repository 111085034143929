import { styled } from 'styled-components';

const StyledMessageStripe = styled.div`
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;

  &.danger {
    border-color: #b90e0a;
    background-color: #b90e0a50;
  }
`;

export { StyledMessageStripe };
